import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/hero'
import { Helmet } from "react-helmet"
import { LinkSection, RightSection, LeftSection, HomeButton } from '../components/standardtile'
import BlockQuote from '../components/blockquote'

// styles
const MainBodySection = styled.article`
  max-width: 960px;
  margin: 0 auto 50px;

  > p {
    color: #b13a04;
    max-width: 600px;
    margin: 30px auto 30px auto;
    line-height: 24px;

    @media only screen and (max-width: 900px) {
      padding: 0 20px;
    }
  }
}
`;

const ShawQuote = {content: 'Opera is when a tenor and soprano want to make love, but are prevented from doing so by a baritone.', quotee: 'George Bernard Shaw'}


// markup
const OperasPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Operas | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas" />
          <meta name="description" content="What is an opera? Learn all about the art form and the operas of Puccini, Verdi, Tchaikovsky, Mozart, Rossini and Britten." />
        </Helmet>
        <Hero title="Operas"/>
        <MainBodySection>
          <BlockQuote quote={ShawQuote}/>
          <p>Though it might seem like a silly question, what exactly is an opera? There's no easy answer. Michael Leiris in his work Operatics defines opera as, "any theatrical work whose basic medium is song. A wide variety of genres fall under this definition: opera strictly speaking, comic opera, light opera, operetta, musicals, lyric drama, etc." The common conception of opera might be fat women in horned helmets but the reality is it's an art form not all that easily pinned down. <i>Il Trovatore</i> is an opera by all accounts but what about <i>Porgy and Bess</i> or <i>Sweeney Todd</i>?</p>
     
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>It's a difficult path to tread to elevate opera above operettas and musicals since the forms aren't readily separated. There are probably more objective differences between Birtwistle's <i>The Minotaur</i> and Puccini's <i>La Fanciulla del West</i> than the latter work and Lloyd Webber's <i>Phantom of the Opera</i>, yet the former two are commonly called operas and the latter a musical!</p>

          <p>All this said, "opera strictly speaking" is not well understood, so we'll start with them!.</p>
        
          <LinkSection>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/barbiere"><StaticImage src="../images/operas/barbiere.jpg" alt="The Barber of Seville"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/barbiere">The Barber of Seville</Link></h3>
                <h4>Rossini</h4>

                <p>One of the more purely entertaining evenings to be had in an opera house. An archetype of opera buffa where everyone ends up smiling and singing together.</p>

                <HomeButton><Link to="/operas/barbiere">Discover Barber of Seville</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/boheme"><StaticImage src="../images/operas/boheme.jpg" alt="La bohème"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/boheme">La bohème</Link></h3>
                <h4>Puccini</h4>

                <p><i>La bohème</i> is an absolute warhorse of the operatic repertory, a triumphant blend of grim reality and soaring romanticism.</p>

                <HomeButton><Link to="/operas/boheme">Discover La bohème</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/carmen"><StaticImage src="../images/operas/carmen.jpg" alt="Carmen"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/carmen">Carmen</Link></h3>
                <h4>Bizet</h4>

                <p><i>Carmen</i> is packed with tunes you’ll already know and a dramatic narrative that ticks all the Romantic opera boxes.</p>

                <HomeButton><Link to="/operas/carmen">Discover Carmen</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/cavalleria"><StaticImage src="../images/operas/cavalleria.jpg" alt="Cavalleria rusticana"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/cavalleria">Cavalleria rusticana</Link></h3>
                <h4>Bizet</h4>

                <p><i>Cavalleria rusticana</i> is an intense, eighty-minute rollercoaster of an opera. A classic tale of romance, betrayal and revenge.</p>

                <HomeButton><Link to="/operas/cavalleria">Discover Cavalleria rusticana</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/giovanni"><StaticImage src="../images/operas/giovanni.jpg" alt="Don Giovanni"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/giovanni">Don Giovanni</Link></h3>
                <h4>Mozart</h4>

                <p>A triumphant retelling of the Don Juan legend capturing all the thrill of an adventure with a dash of comedy, a dose of tragedy and a challengingly dark underpinning.</p>

                <HomeButton><Link to="/operas/giovanni">Discover Don Giovanni</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/onegin"><StaticImage src="../images/operas/onegin.jpg" alt="Eugene Onegin"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/onegin">Eugene Onegin</Link></h3>
                <h4>Tchaikovsky</h4>

                <p><i>Eugene Onegin</i> is a radiant example of Russian Lyric Opera. A deeply moving tale packed with big tunes, dances and a heart-stopping duel.</p>

                <HomeButton><Link to="/operas/onegin">Discover Eugene Onegin</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/butterfly"><StaticImage src="../images/operas/butterfly.jpg" alt="Madama Butterfly"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/butterfly">Madama Butterfly</Link></h3>
                <h4>Puccini</h4>

                <p>Puccini at his most restrained. A single setting, a relatively simple plot, and a strongly drawn lead pair around whom the emotionally gripping narrative spins.</p>

                <HomeButton><Link to="/operas/butterfly">Discover Madama Butterfly</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/flute"><StaticImage src="../images/operas/flute.jpg" alt="The Magic Flute"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/flute">The Magic Flute</Link></h3>
                <h4>Mozart</h4>

                <p>A fantastical evening full of sorcerers, heroes, magical instruments, wild animals, and special effects.</p>

                <HomeButton><Link to="/operas/flute">Discover The Magic Flute</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/figaro"><StaticImage src="../images/operas/figaro.jpg" alt="The Marriage of Figaro"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/figaro">The Marriage of Figaro</Link></h3>
                <h4>Mozart</h4>

                <p>Essentially a farce, built on mistaken identities and misunderstandings centered around the upstairs/downstairs power dynamics of so many classic comedies.</p>

                <HomeButton><Link to="/operas/figaro">Discover Figaro</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/dream"><StaticImage src="../images/operas/dream.jpg" alt="A Midsummer Night's Dream"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/dream">A Midsummer Night's Dream</Link></h3>
                <h4>Britten</h4>

                <p>An evening of pure magic. A show that rewards repeat viewings whilst still managing to be instantly accessible. Genuinely funny and a deliciously romantic evening too.</p>

                <HomeButton><Link to="/operas/dream">Discover MSND</Link></HomeButton>
              </RightSection>
            </div>            
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/grimes"><StaticImage src="../images/operas/grimes.jpg" alt="Peter Grimes"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/grimes">Peter Grimes</Link></h3>
                <h4>Britten</h4>

                <p>A big opera with a very tight focus. There are huge choral scenes and a large cast of supporting players but the work holds fast to Grimes himself.</p>

                <HomeButton><Link to="/operas/grimes">Discover Peter Grimes</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/rigoletto"><StaticImage src="../images/operas/rigoletto.jpg" alt="Rigoletto"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/rigoletto">Rigoletto</Link></h3>
                <h4>Verdi</h4>

                <p>One of the most exciting and gritty operas, <i>Rigoletto</i> is not for the faint-hearted. The censors of the time had real issues with <i>Rigoletto’s</i> content and it’s not really hard to see why!</p>

                <HomeButton><Link to="/operas/rigoletto">Discover Rigoletto</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/screw"><StaticImage src="../images/operas/screw.jpg" alt="The Turn of the Screw"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/screw">The Turn of the Screw</Link></h3>
                <h4>Britten</h4>

                <p>An operatic horror story, <i>The Turn of the Screw</i> is a skin crawling beauty. An exploration of innocence corrupted, a descent into despair and madness.</p>

                <HomeButton><Link to="/operas/screw">Discover Turn of the Screw</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/tosca"><StaticImage src="../images/operas/tosca.jpg" alt="Tosca"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/tosca">Tosca</Link></h3>
                <h4>Puccini</h4>

                <p>One of the most lethal of operas. None of the central characters make it to the end alive, hero or villain. Puccini takes an overtly theatrical tale and makes it astonishingly moving.</p>

                <HomeButton><Link to="/operas/tosca">Discover Tosca</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/traviata"><StaticImage src="../images/operas/traviata.jpg" alt="La Traviata"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/traviata">La Traviata</Link></h3>
                <h4>Verdi</h4>

                <p>One of the cornerstones of the operatic repertory. The taut plot and vivid characters paired with Verdi’s perfectly attuned music tend to result in few dry eyes by the end of Act III.</p>

                <HomeButton><Link to="/operas/traviata">Discover La Traviata</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection sizedimage="180px">
                <Link to="/operas/turandot"><StaticImage src="../images/operas/turandot.jpg" alt="Turandot"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operas/turandot">Turandot</Link></h3>
                <h4>Puccini</h4>

                <p><i>Turandot</i> is Puccini’s final opera (unfinished at his death) and perhaps as a result, it is by far and away his most musically adventurous.</p>

                <HomeButton><Link to="/operas/turandot">Discover Turandot</Link></HomeButton>
              </RightSection>
            </div>
          </LinkSection>
        </MainBodySection>
    </Layout>
  )
}

export default OperasPage
